<template>
  <Page>
    <v-snackbar v-model="snackbar" :timeout="3000" color="red">
      This link is invalid or expired
    </v-snackbar>
    <loading-circle v-if="loading" />
  </Page>
</template>
<script>
import LoadingCircle from '@/components/LoadingCircle.vue';
import * as base64 from 'base-64';
import { auth } from '../../sharedPlugin';
export default {
  components: { LoadingCircle },
  data() {
    return {
      snackbar: false,
      id: null,
      loading: false
    };
  },
  computed: {
    magicLinkToken() {
      const magicLinkToken = this.$route?.params?.token;
      return magicLinkToken;
    }
  },
  methods: {
    ...auth.mapMethods(['magicLinkLogin']),
    async loginWithMagicLink() {
      await this.magicLinkLogin({
        id: this.$route?.params?.id,
        token: this.$route?.params?.token
      })
        .then((response) => {
          if (this.$route.params.route) {
            this.$router.push({
              path: `/${base64.decode(this.$route.params.route)}`
            });
          } else {
            this.$router.push(`/set-password/${response.data.token}`);
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log('Error', err);
        });
    }
  },
  async mounted() {
    this.loading = true;
    if (this.magicLinkToken) {
      this.loginWithMagicLink();
    } else {
      //No valid token ? stop magic linking....
      console.log('No Magic Link Token found.');
      // this.callMagicLinkEndpoint();
    }
  }
};
</script>
